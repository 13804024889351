import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type GlobalSliceType = {
  isOverlayShow: boolean;
  width: number;
  height: number;
  isMobile: boolean;
};

const initialState: GlobalSliceType = {
  isOverlayShow: false,
  width: 0,
  height: 0,
  isMobile: false,
};

export const MenuSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsOverlayShow: (state, action: PayloadAction<boolean>) => {
      state.isOverlayShow = action.payload;
    },
    setWidth: (state, action: PayloadAction<number>) => {
      state.width = action.payload;
    },
    setHeight: (state, action: PayloadAction<number>) => {
      state.width = action.payload;
    },
    setMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
  },
});

export const { setIsOverlayShow, setWidth, setHeight, setMobile } =
  MenuSlice.actions;
