import { ThemeProvider } from "@emotion/react";
import React from "react";
import { Provider } from "react-redux";
import store from "./src/store";
import theme from "./src/styles/theme";
import "./src/styles/destyle.css";
import "./src/styles/globals.scss";

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>{element}</Provider>
    </ThemeProvider>
  );
};

export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
        await import(`intersection-observer`);
    }

    // Object-fit/Object-position polyfill for gatsby-image (IE)
    const testImg = document.createElement(`img`)
    if (
        typeof testImg.style.objectFit === `undefined` ||
        typeof testImg.style.objectPosition === `undefined`
    ) {
        import(`object-fit-images`)()
        console.log(`👍 Object-fit/Object-position are polyfilled`)
    }
}

export const onInitialClientRender = () => {
    const isIeBrowser = !!window.MSCompatibleInfo;
    if (isIeBrowser) {
        window.location.replace('/ie11.html');
    }
}
